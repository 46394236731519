/* global Component, uuidv4 */
class itemProxyComponent extends Component {

    getProps() {
        return  {
            itemData: {
                type: Object,
                required: false
            },
            imgUseZoom: {
                type: Boolean,
                default: true
            },
            imgSlideBigImage: {
                type: Boolean,
                default: false
            },
            imgShowCarusel: {
                type: Boolean,
                default: true
            },
            showRelatedItems:{
                type: Boolean,
                default: false
            },
        };
    }

    data() {
        return {
            itemInCar:0,
            Qty:1,
            favorite:false,
            loadingPrice:false,
            alertMsg:null,
            showClassification:false,
            varieties : []
        };
    }

    static name() {
        return "singleItemComponent";
    }

    static componentName() {
        return "singleItemComponent";
    }

    mounted(){
        return async function (){
            await this.loadItemPrices();
            if(this.item) {
                const currency = this.$store.state.priceDeal.priceDeal ? 
                    this.$store.state.priceDeal.priceDeal.fields.Currency :
                    this.$store.state.setting.Currency;
                this.gtmTrackEcommerceViewItem(this.item, currency);
            }
        };
    }

    updated(){
        return async function (){
            await this.loadItemPrices();
        };
    }

    getMethods() {
        return {
            getItemUrl:this.getItemUrl,
            loadItemPrices:this.loadItemPrices,
            alertShowMessage:this.alertShowMessage,
            ItemComponentAddToCar:this.ItemComponentAddToCar,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            onChangeQty:this.onChangeQty,
            goBack:this.goBack,
            filterByCategory:this.filterByCategory,
            getMaxStockByCode: this.getMaxStockByCode,
            updateQtyFormula: this.updateQtyFormula,
        };
    }

    getComputed() {
        const superComputed = super.getComputed();
        superComputed.getQtyFormulaFields = function (){
            return this.$store.getters.getQtyFormulaFields;
        };
        superComputed.canUseOrderQtyFormula = function (){
            return this.$store.getters.canUseOrderQtyFormula;
        };
        superComputed.pageTitle = function (){
            return this.item ? this.item.Name : superComputed.pageTitle;
        };
        superComputed.canShopItem = function (){
            return this.controlStock ? this.item.Qty > 0 : true;
        };
        superComputed.showStock = function (){
            return this.$store.getters.showStock;
        };
        superComputed.useVariates = function (){
            return this.item && this.item.ItemType === 6;
        };
        superComputed.allowDecimal = function (){
            if(this.item.OnlyIntegerValues !== 1)
                return "any";
            return 1;
        };
        superComputed.enableLK = function (){
            return this.$store.state.setting.EnableLK;
        };
        superComputed.linkLK = function (){
            let link = "#";
            if(this.$store.state.setting.LKLink)
                link = this.$store.state.setting.LKLink;
            return link;
        };
        superComputed.enableIG = function (){
            return this.$store.state.setting.EnableIG;
        };
        superComputed.linkIG = function (){
            let link = "https://instagram.com";
            if(this.$store.state.setting.IGLink)
                link = this.$store.state.setting.IGLink;
            return link;
        };
        superComputed.enableFB = function () {
            return this.$store.state.setting.EnableFB;
        };
        superComputed.enableTW = function () {
            return this.$store.state.setting.EnableTW;
        };
        superComputed.controlStock = function (){
            return this.$store.getters.getControlStock;
        };
        superComputed.item = function (){
            let storeItem = this.itemData ? this.itemData : this.$store.getters.getItemByCode(this.$route.params.id);
            return storeItem;
        };
        superComputed.classificationList = function (){
            let storeItem = this.$store.getters.getItemByCode(this.$route.params.id);
            let listClass = [];
            if(storeItem.Classification)
                for(let catCode of storeItem.Classification.split(',')){
                    let tempCat = this.$store.getters.getCategoryDataByCode(catCode);
                    if(tempCat)
                        listClass.push(tempCat);
                }
            return listClass;
        };
        superComputed.relatedItems = function (){
            if(!this.loadingPrice && this.$store.state.itemsFullLoader && this.item && this.item.RelatedItems){
                let related = [];
                for(let relatedCode of this.item.RelatedItems.split(',')){
                    let storeItem = this.$store.getters.getItemByCode(relatedCode);
                    if(storeItem)
                        related.push(storeItem);
                }
                return related;
            }
            return [];
        };
        superComputed.codeToShow = function (){
            let settings = this.$store.getters.getSetting;
            if(!settings.CodeFieldDisplay){
                return this.item.Code;
            }else if(settings.CodeFieldDisplay == 1){
                return this.item.AlternativeCode;
            }else if(settings.CodeFieldDisplay == 2){
                return this.item.BarCode;
            }else if(settings.CodeFieldDisplay == 3){
                return this.item.BarCode2;
            }
        };
        return superComputed;
    }

    updateQtyFormula(field, event) {
        let fieldValue = event.target.valueAsNumber;
        this.item[field] = fieldValue;
    }

    onChangeQty(event){
        if(!isNaN(event.target.value)) {
            const newValue = Number.parseInt(event.target.value,10);
            const maxQty = this.getMaxStockByCode(this.item.Code);
            if(newValue <= maxQty)
                this.Qty = newValue;
            else {
                if(maxQty === 0)
                    this.alertShowMessage("ITEM_STOCK_ZERO");
                else
                    this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                this.Qty = maxQty;
            }
        } else {
            this.alertShowMessage([`FORMATVALUEERR` ,'Qty']);
            this.Qty = 1;
        }
    }

    getItemUrl(){
        return encodeURI(window.location.href);
    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(this.$store.getters.getSetting.ControlStockForSales &&  !(giftCardItem && giftCardItem.fields.Code == itemCode)) {
            let itemInStore = this.$store.getters.getItemByCode(itemCode);
            const qtyInCar = this.$store.getters.getItemAmountInCar(itemCode);
            if(itemInStore.Reserved)
                return itemInStore.Qty - itemInStore.Reserved - qtyInCar;
            return itemInStore.Qty - qtyInCar;
        }
        return 10000000;
    }

    loadItemPrices(){
        let storeItem = this.$store.getters.getItemByCode(this.$route.params.id);
        if(storeItem) {
            if(storeItem.Classification)
                this.$store.dispatch("updateCategory",this.$store.getters.getCategoryDataByCode(storeItem.Classification.split(',')[0]));

            if(this.$store.getters.getSetting.ShowPriceByDefault || this.$store.getters.getIsLogin){

                let  itemWithOutPrice = [];
                if(!storeItem.isCalculate) {
                    this.loadingPrice = true;
                    itemWithOutPrice.push(storeItem);
                }
                if(this.item.RelatedItems)
                    for(let relatedCode of this.item.RelatedItems.split(',')){
                        let relatedItem = this.$store.getters.getItemByCode(relatedCode);
                        if(relatedItem && !relatedItem.isCalculate){
                            this.loadingPrice = true;
                            itemWithOutPrice.push(relatedItem);
                        }
                    }
                if(itemWithOutPrice.length > 0)
                    this.$store.dispatch("updatePrices",itemWithOutPrice).then((response)=>{
                        this.loadingPrice = false;
                    });
            }
        }
    }

    alertShowMessage(alertMsg){
        this.alertMsg = alertMsg;
        setTimeout(()=> this.alertMsg = null, 4500);
    }

    ItemComponentAddToCar(){
        if(isNaN(this.Qty) || this.Qty == 0) {
            this.Qty = 0;
            return;
        }

        if(this.controlStock) {
            let itemInCar = this.$store.getters.getItemCar.filter(x => x.id == this.item.Code)[0];
            const maxQty = this.getMaxStockByCode(this.item.Code);
            if (itemInCar) {
                let formaterQty;
                let formaterCarQty;
                if(this.item.OnlyIntegerValues === 1) {
                    formaterQty = parseInt(this.Qty);
                    formaterCarQty = parseInt(itemInCar.cant);
                }
                else{
                    formaterQty = Math.round((parseFloat(this.Qty) + Number.EPSILON) * 100) / 100;
                    formaterCarQty = Math.round((parseFloat(itemInCar.cant) + Number.EPSILON) * 100) / 100;
                }
                if (formaterCarQty + formaterQty > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
            else{
                if (parseInt(this.Qty) > this.item.Qty) {
                    this.alertShowMessage([`This item {{$1}} is not longer available`,this.item.Name]);
                    return;
                }
            }
        }
        this.item.carQty = this.Qty;
        this.$store.dispatch('ItemComponentAddToCar',this.item).then(()=>{
            const currency = this.$store.state.priceDeal.priceDeal ? 
                this.$store.state.priceDeal.priceDeal.fields.Currency :
                this.$store.state.setting.Currency;
            this.gtmTrackEcommerceAddToCart(this.item, this.Qty, currency, this.Qty * (this.item.RowTotal || this.item.RowNet || this.item.Price));
        });
        
    }

    itemQtyIncrement(){
        const maxQty = this.getMaxStockByCode(this.item.Code);
        if(Number.isInteger(this.Qty) && this.Qty + 1 <= maxQty)
            this.Qty += 1;
        else { 
            if(maxQty === 0)
                this.alertShowMessage("ITEM_STOCK_ZERO");
            else
                this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
        }
    }

    itemQtyDecrement(){
        if(this.Qty > 1)
            this.Qty -= 1;
        else
            this.Qty = 0;
    }

    goBack(){
        this.$router.go(-1);
    }

    filterByCategory(cat){
        if(cat) {
            this.$store.commit('updateAppState', 'loading');
            let objectFilter = {};
            objectFilter.name = uuidv4();
            objectFilter.type = 'globalClassification';
            //objectFilter.condition = `x.Classification.split(',').indexOf('${cat.code}')!=-1`;
            objectFilter.condition = `x.ClassificationPath['${cat.code}']!=undefined`;
            objectFilter.label = cat.code;
            objectFilter.filterField = "Classification";
            this.$store.dispatch('addGlobalFilter', objectFilter);
            this.$store.dispatch('updateCategory', cat);
            window.scrollTo(0, 0);
            //window.Event.$emit("category-select");
            this.emitEvent("category-select");
            //console.log('after emit event');
            //this.$store.commit('updateAppState', "ready");
            this.$router.push('/items?category=' + encodeURI(`${cat.code}__${cat.name}`));
        }
        if (this.isMobile) {
            this.emitEvent('closeSandwichMenu');
        }
    }

    getTemplate(){
        return `<div class="item-detail-container" v-if="!isLoading">
                    <template v-if="!useVariates">
                        <itemStockComponent v-bind="{...$props, ...$attrs}" />     
                    </template>
                    <template v-else>
                        <itemVarietiesComponent v-bind="{...$props, ...$attrs}"/>
                    </template>
			    </div>`;
    }

}

itemProxyComponent.registerComponent();
